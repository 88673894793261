export const MEASUREMENT_SYSTEM_KEYS = {
  METRIC: 'metric',
  IMPERIAL: 'imperial'
};

export const measurementSystemOptions = [
  { key: MEASUREMENT_SYSTEM_KEYS.METRIC, label: 'Metric' },
  { key: MEASUREMENT_SYSTEM_KEYS.IMPERIAL, label: 'Imperial' },
];

export const unitOptions = {
  [MEASUREMENT_SYSTEM_KEYS.METRIC]: [
    { key: 'kg', label: 'Kilograms' },
    { key: 'g', label: 'Grams' }
  ],
  [MEASUREMENT_SYSTEM_KEYS.IMPERIAL]: [
    { key: 'oz', label: 'Ounces'},
    { key: 'lb', label: 'Pounds' },
  ]
};

export const allUnitOptions = [
  ...unitOptions[MEASUREMENT_SYSTEM_KEYS.METRIC],
  ...unitOptions[MEASUREMENT_SYSTEM_KEYS.IMPERIAL]
];
