import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { LayoutSingleColumn, NamedLink, Page } from '../../components';

import Header from './components/HeaderContainer';
import Footer from './components/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import BottomBar from './components/BottomBar';
import Overlay from './components/Overlay';
import SellerForm from './components/SellerForm';
import BuyerSignupForm from './components/BuyerSignupForm';
import SignupSection from './components/SignupSection';
import BecomeASellerSignupSection from './components/BecomeASellerSignupSection';
import { submitBuyerData } from './BecomeASellerStaticPage.duck';

import css from './BecomeASellerStaticPage.module.css';

const OurStorySections = () => {
  return (
    <>
      <section
        id={css['our-story-intro']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css.text1}>
            <h1>
              Our <span className={`${css['c-highlight3']}`}>story</span>
            </h1>
          </div>
          <div className={css.imageblock}>
            <div id={css['sent-to-artisans']} className={`${css.image} ${css.image1}`}>
              <div className={css['happiness-image']}>
                <img 
                  src="/static/images/our-story/portrait.png"
                  width="499" 
                  height="400" 
                  className={css['happiness-artist-portrait']} 
                  loading="lazy" 
                />
              </div>
              <div className={css['happiness-text']}>
                <div className={css.heading}>
                  YOU are helping us to spread real
                  <img 
                    alt="" 
                    src="/static/images/our-story/happiness-large.png" 
                    width="212" 
                    height="65" 
                    className={css['happiness-tagline']} 
                    loading="lazy" 
                  />
                </div>
                <div className={css.details}>
                  <span className={css.description}>
                    So far, our customers have helped us<br /> send artisans over
                  </span>
                  <span className={css.figure}>
                    $130,000,000<span className={css.currency}> USD</span>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['world-of-creators']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              The world is{' '}
              <span className={`${css['c-highlight1']}`}>
                full of creators.
              </span>
            </h4>
            <p>
              People who make things by hand. Who craft in the traditions of our
              ancestors. Who harness imagination to make entirely new creations.
            </p>
          </div>
          <div className={css.text}>
            <h4>
              But so many artisans still don't have access to{' '}
              <span className={`${css['c-highlight3']}`}>
                the customers they deserve.
              </span>
            </h4>
            <p>
              And buyers are unable to find the time-honored creations of the
              world's most talented artisans.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['our-roots-trace-back']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <p className={css.arrow}>
            <img src="/static/images/our-story/arrow-right-highlight1.png" />
          </p>
          <div className={css.text}>
            <h3>
              <span className={`${css['c-highlight1']}`}>Handmade.com</span>{' '}
              wants to change that.
            </h3>
            <h5>
              Our roots trace back to 1999, when our parent company, NOVICA,
              bravely pioneered an online marketplace that revolutionized the
              import/export experience for artisans.
            </h5>
            <p>
              By removing the middlemen, NOVICA brought global artisans and
              enthusiastic customers together for the first time without layers
              of markups and intermediaries. The result was life-changing for
              artisans.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/our-story/section3-image1.jpg"
                loading="lazy"
              />
            </div>
            <div className={`${css.image} ${css.image2}`}>
              <img
                src="/static/images/our-story/section3-image2.jpg"
                loading="lazy"
              />
            </div>
            <div className={`${css.image} ${css.image3}`}>
              <img
                src="/static/images/our-story/section3-image3.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['artisan-empowerment-hubs']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              Artisans suddenly enjoyed unprecedented access to customers{' '}
              <span className={`${css['c-highlight1']}`}>
                thousands of miles away.
              </span>
            </h4>
            <p>
              They were empowered to set their own prices and earn fair returns
              on their labor, using techniques passed down for generations. And
              customers across the globe fell in love with their extraordinary
              handmade works.
            </p>
          </div>
          <div className={css.text}>
            <h4>
              Over the past two decades, NOVICA built{' '}
              <span className={`${css['c-highlight3']}`}>
                artisan empowerment hubs
              </span>{' '}
              in local communities around the world.
            </h4>
            <p>
              Including West Africa, Mexico, Central America, Peru, Brazil,
              Armenia, Thailand, Bali, India, and Central Asia. We have watched
              firsthand as artisans gain their financial freedom, take renewed
              pride in their work, uplift their communities, and so much more.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['partnered-with-natgeo']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block2} ${css.spaceright}`}>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/our-story/section5-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              <span className={`${css['c-highlight1']}`}>We partnered</span>{' '}
              with National Geographic, the Smithsonian Folklife Festival, USAID
              and the International Trade Centre (an affiliate of the United
              Nations)
            </h4>
            <p>
              To reach more artisans and spread the word to more customers,
              NOVICA built long-lasting relationships with some of the most
              amazing and influential institutions around the world.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['create-products-by-hand']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              Now we're expanding our reach to include ALL artisans who{' '}
              <span className={`${css['c-highlight3']}`}>
                create products by hand.
              </span>
            </h4>
            <p>
              From a wood carver in the most remote region of the planet to your
              next door neighbor who creates beautiful pottery on the wheel in
              her garage.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['the-creators-journey']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css.text}>
            <h4>
              Creators are overflowing with inspiration, and customers are
              hungry for unique, ethically-made products that add meaning to
              their lives.
            </h4>
            <p>
              For over 20 years we've been on this journey. We can't wait to see
              where this adventure takes us next.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={css.imagegroup}>
              <div className={`${css.image} ${css.image1}`}>
                <img
                  src="/static/images/our-story/section7-image1.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css.image2}`}>
                <img
                  src="/static/images/our-story/section7-image2.jpg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['welcome-to-handmade']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css.text1}>
            <h3>
              Welcome to{' '}
              <span className={`${css['c-highlight1']} ${css.underline}`}>
                handmade.com
                <span className={css.underline}>
                  <img src="/static/images/our-story/underline-highlight1.png" />
                </span>
              </span>
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

const OurStoryStaticPage = () => {
  // const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenGatewayDialog, setIsOpenGatewayDialog] = useState(false);
  const [isShowSellerForm, setIsShowSellerForm] = useState(false);
  const [isShowBuyerForm, setIsShowBuyerForm] = useState(false);

  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  useEffect(() => {
    if (!isOpenGatewayDialog) {
      setIsShowSellerForm(false);
      setIsShowBuyerForm(false);
    }
  }, [isOpenGatewayDialog]);

  useEffect(() => {
    if (isShowSellerForm || isShowBuyerForm) {
      setIsOpenGatewayDialog(true);
    }
  }, [isShowSellerForm, isShowBuyerForm]);

  const intl = useIntl();

  const dispatch = useDispatch();
  const onSubmitBuyerData = values => dispatch(submitBuyerData(values));

  const handleSubmitBuyerData = values => {
    return onSubmitBuyerData(values);
  };

  const shouldOpenDialog = isOpenGatewayDialog;

  const title = intl.formatMessage({ id: 'BecomeASellerStaticPage.title' });

  return (
    <Page
      className={css.staticPageBase}
      title={title}
      scrollingDisabled={shouldOpenDialog || scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterComponent />}
      >
        <div
          className={classNames(css.handmadecontent, {
            [css['dialog-open']]: shouldOpenDialog,
          })}
        >
          <div id={css['main-canvas']} className={css.container}>
            <div id={css['main-content']}>
              <OurStorySections />
            </div>
            <BecomeASellerSignupSection
              onOpenSellerForm={setIsShowSellerForm}
              onOpenBuyerForm={setIsShowBuyerForm}
            />
          </div>
          <BottomBar onOpenGatewayDialog={setIsOpenGatewayDialog} />
          <Overlay
            isOpenSellerForm={isShowSellerForm}
            onOpenGatewayDialog={setIsOpenGatewayDialog}
          >
            {isShowSellerForm || isShowBuyerForm ? (
              isShowSellerForm ? (
                <SellerForm />
              ) : (
                <BuyerSignupForm onSubmit={handleSubmitBuyerData} />
              )
            ) : (
              isOpenGatewayDialog && (
                <SignupSection
                  onOpenSellerForm={setIsShowSellerForm}
                  onOpenBuyerForm={setIsShowBuyerForm}
                />
              )
            )}
          </Overlay>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default OurStoryStaticPage;
