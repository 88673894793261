import { storableError } from '../../util/errors';
import { submitBuyerDataToNovica } from '../../util/staticPageApi';

// ================ Action types ================ //

export const SUBMIT_BUYER_DATA_REQUEST =
  'app/BecomeASellerStaticPage/SUBMIT_BUYER_DATA_REQUEST';
export const SUBMIT_BUYER_DATA_SUCCESS =
  'app/BecomeASellerStaticPage/SUBMIT_BUYER_DATA_SUCCESS';
export const SUBMIT_BUYER_DATA_ERROR =
  'app/BecomeASellerStaticPage/SUBMIT_BUYER_DATA_ERROR';

// ================ Reducer ================ //

const initialState = {
  submitBuyerDataError: null,
  submitBuyerDataInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SUBMIT_BUYER_DATA_REQUEST:
      return {
        ...state,
        submitBuyerDataInProgress: true,
        submitBuyerDataError: null,
      };
    case SUBMIT_BUYER_DATA_SUCCESS:
      return {
        ...state,
        submitBuyerDataInProgress: false,
      };
    case SUBMIT_BUYER_DATA_ERROR:
      return {
        ...state,
        submitBuyerDataInProgress: false,
        submitBuyerDataError: payload,
      };

    default:
      return state;
  }
}

// ================ Action creators ================ //

export const submitBuyerDataRequest = () => ({
  type: SUBMIT_BUYER_DATA_REQUEST,
});
export const submitBuyerDataSuccess = () => ({
  type: SUBMIT_BUYER_DATA_SUCCESS,
});
export const submitBuyerDataError = error => ({
  type: SUBMIT_BUYER_DATA_ERROR,
  payload: error,
  error: true,
});

// ================ Thunks ================ //

export const submitBuyerData = params => (dispatch, getState, sdk) => {
  dispatch(submitBuyerDataRequest());

  const { firstName, email } = params;

  return submitBuyerDataToNovica({ firstName, email })
    .then(() => {
      dispatch(submitBuyerDataSuccess());
      return true;
    })
    .catch(e => {
      dispatch(submitBuyerDataError(storableError(storableError(e))));
      return false;
    });
};