import classNames from 'classnames';
import {
  array,
  arrayOf,
  bool,
  func,
  object,
  oneOf,
  shape,
  string,
} from 'prop-types';
import React, { useMemo, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { compose } from 'redux';
import isEmpty from 'lodash/isEmpty';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { initializeCardPaymentData } from '../../ducks/stripe.duck.js';
import {
  isScrollingDisabled,
  manageDisableScrolling,
} from '../../ducks/ui.duck';
import { convertMoneyToNumber } from '../../util/currency';
import {
  ensureListing,
  ensureOwnListing,
  ensureUser,
  userDisplayNameAsString,
} from '../../util/data';
import { FormattedMessage, intlShape, useIntl } from '../../util/reactIntl';
import { richText } from '../../util/richText';
import { types as sdkTypes } from '../../util/sdkLoader';
import {
  CHECKOUT_TYPE,
  LISTING_STATE_CLOSED,
  LISTING_STATE_PENDING_APPROVAL,
  SCHEMA_TYPE_MULTI_ENUM,
  propTypes,
} from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PARAM_TYPE_DRAFT,
  LISTING_PAGE_PARAM_TYPE_EDIT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  createSlug,
} from '../../util/urlHelpers';

import {
  Avatar,
  H4,
  IconSpinner,
  LayoutSingleColumn,
  NamedLink,
  NamedRedirect,
  OrderPanel,
  Page,
} from '../../components';

import FooterContainer from '../FooterContainer/FooterContainer';
import NotFoundPage from '../NotFoundPage/NotFoundPage';
import TopbarContainer from '../TopbarContainer/TopbarContainer';

import {
  fetchReviews,
  fetchTimeSlots,
  fetchTransactionLineItems,
  sendInquiry,
  setInitialValues,
} from './ListingPage.duck';

import { stringifyDateToISO8601 } from '../../util/dates';
import { getVariationValues } from '../../util/dynamicFieldsHelpers';
import ActionBarMaybe from './ActionBarMaybe';
import {
  ErrorPage,
  LoadingPage,
  handleContactUser,
  handleSubmit,
  handleSubmitInquiry,
  listingImages,
} from './ListingPage.shared';
import SectionAuthorMaybe from './SectionAuthorMaybe';
import SectionBullets from './SectionBullets';
import SectionGallery from './SectionGallery';
import SectionMapMaybe from './SectionMapMaybe';
import SectionMultiEnumMaybe from './SectionMultiEnumMaybe';
import SectionReviews from './SectionReviews';
import SectionShippingInformation from './SectionShippingInformation';
import SectionTags from './SectionTags';
import SectionTextMaybe from './SectionTextMaybe';

import css from './ListingPage.module.css';

const MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE = 16;
const REVIEWS = 'reviews';

const { UUID } = sdkTypes;

export const ListingPageComponent = props => {
  const {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    intl,
    onManageDisableScrolling,
    params: rawParams,
    location,
    scrollingDisabled,
    showListingError,
    reviews,
    fetchReviewsError,
    fetchReviewsInprogress,
    sendInquiryInProgress,
    sendInquiryError,
    monthlyTimeSlots,
    onFetchTimeSlots,
    listingConfig: listingConfigProp,
    onFetchTransactionLineItems,
    onFetchMoreReviews,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    history,
    callSetInitialValues,
    onSendInquiry,
    onInitializeCardPaymentData,
    config,
    routeConfiguration,
    productAttributes,
    listingId,
  } = props;

  const {
    storeListingInformation,
    productTaxCode,
    makerListing,
    fetchMakerListingInProgress,
    fetchMakerListingError,
  } = useSelector(state => state.ListingPage);

  const [inquiryModalOpen, setInquiryModalOpen] = useState(
    props.inquiryModalOpenForListingId === props.params.id
  );
  const [selectedTab, setSelectedTab] = useState(REVIEWS);
  const [productVariationSelected, setProductVariationSelected] = useState(
    null
  );
  // prop override makes testing a bit easier
  // TODO: improve this when updating test setup
  const listingConfig = listingConfigProp || config.listing;

  const isPendingApprovalVariant =
    rawParams.variant === LISTING_PAGE_PENDING_APPROVAL_VARIANT;

  if (isPendingApprovalVariant) {
    return <NamedRedirect name="ManageListingsPage" />;
  }

  const isDraftVariant = rawParams.variant === LISTING_PAGE_DRAFT_VARIANT;
  const currentListing =
    isPendingApprovalVariant || isDraftVariant
      ? ensureOwnListing(getOwnListing(listingId))
      : ensureListing(getListing(listingId));

  const productSlug = currentListing.attributes?.publicData?.productName;
  const storeSlug =
    currentListing.author?.attributes?.profile?.publicData?.storeName;

  const listingSlug =
    rawParams.slug || createSlug(currentListing.attributes.title || '');
  const params = { ...rawParams, productSlug, id: listingId.uuid };

  const listingPathParamType = isDraftVariant
    ? LISTING_PAGE_PARAM_TYPE_DRAFT
    : LISTING_PAGE_PARAM_TYPE_EDIT;
  const listingTab = isDraftVariant ? 'photos' : 'details';

  const isApproved =
    currentListing.id &&
    currentListing.attributes.state !== LISTING_STATE_PENDING_APPROVAL;

  const pendingIsApproved = isPendingApprovalVariant && isApproved;

  // If a /pending-approval URL is shared, the UI requires
  // authentication and attempts to fetch the listing from own
  // listings. This will fail with 403 Forbidden if the author is
  // another user. We use this information to try to fetch the
  // public listing.

  const pendingOtherUsersListing =
    (isPendingApprovalVariant || isDraftVariant) &&
    showListingError &&
    showListingError.status === 403;
  const shouldShowPublicListingPage =
    pendingIsApproved || pendingOtherUsersListing;

  if (shouldShowPublicListingPage) {
    return (
      <NamedRedirect
        name="ListingPage"
        params={params}
        search={location.search}
      />
    );
  }

  const topbar = <TopbarContainer />;

  if (!currentListing.id && !showListingError) {
    // Still loading the listing
    return (
      <LoadingPage
        topbar={topbar}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  } else if (showListingError && showListingError.status === 404) {
    // 404 listing not found
    return <NotFoundPage />;
  } else if (showListingError) {
    // Other error in fetching listing
    return (
      <ErrorPage
        topbar={topbar}
        scrollingDisabled={scrollingDisabled}
        intl={intl}
      />
    );
  }

  const {
    description = '',
    geolocation = null,
    price = null,
    title = '',
    publicData = {},
    metadata = {},
  } = currentListing.attributes;

  if (!rawParams.productSlug) {
    return (
      <NamedRedirect
        name="ListingPage"
        params={{ ...params, productSlug: createSlug(title) }}
      />
    );
  }

  const {
    sku,
    weight,
    weightUnit,
    shippingWeight,
    shippingWeightUnit,
    variationOptions = [],
  } = publicData || {};
  const { totalReviews = 0 } = metadata;

  const {
    weight: variantProductWeight,
    shippingWeight: variantShippingWeight,
  } =
    variationOptions.find(option => option.key === productVariationSelected) ||
    {};

  const productWeightTextValues = `
    Product weight: ${variantProductWeight || weight} ${weightUnit},
    Product shipping weight: ${variantShippingWeight ||
      shippingWeight} ${shippingWeightUnit}
  `;

  const richTitle = (
    <span>
      {richText(title, {
        longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS_IN_TITLE,
        longWordClass: css.longWord,
      })}
    </span>
  );

  const authorAvailable = currentListing && currentListing.author;
  const userAndListingAuthorAvailable = !!(currentUser && authorAvailable);
  const isOwnListing =
    userAndListingAuthorAvailable &&
    currentListing.author.id.uuid === currentUser.id.uuid;

  const currentAuthor = authorAvailable ? currentListing.author : null;
  const ensuredAuthor = ensureUser(currentAuthor);

  // When user is banned or deleted the listing is also deleted.
  // Because listing can be never showed with banned or deleted user we don't have to provide
  // banned or deleted display names for the function
  const authorDisplayName = userDisplayNameAsString(ensuredAuthor, '');

  const { title: storeName } = storeListingInformation.attributes || {};
  const storeImage = storeListingInformation.images?.[0];

  const makerName = makerListing?.attributes?.title;
  const makerImage = makerListing?.images?.[0];
  const makerListingId = makerListing?.id.uuid ?? '';
  const makerListingLinkProps = {
    name: 'MakerListingPage',
    params: {
      makerListingId,
    },
  };
  const storeCustomLinkProps = {
    name: 'StoreListingPage',
    params: {
      storeSlug,
    },
  };

  const linkProps = makerListing ? makerListingLinkProps : storeCustomLinkProps;

  const commonParams = { params, history, routes: routeConfiguration };
  const onContactUser = handleContactUser({
    ...commonParams,
    currentUser,
    callSetInitialValues,
    location,
    setInitialValues,
    setInquiryModalOpen,
  });
  // Note: this is for inquiry state in booking and purchase processes. Inquiry process is handled through handleSubmit.
  const onSubmitInquiry = handleSubmitInquiry({
    listingId,
    ...commonParams,
    getListing,
    onSendInquiry,
    setInquiryModalOpen,
  });
  const onSubmit = handleSubmit({
    ...commonParams,
    listingId,
    currentUser,
    callSetInitialValues,
    getListing,
    onInitializeCardPaymentData,
  });

  const handleOrderSubmit = (values, valuesOptions = {}) => {
    const { variations, variationSKU } = valuesOptions;

    const variationValuesToDisplay = getVariationValues(variations, values);

    const isCurrentlyClosed =
      currentListing.attributes.state === LISTING_STATE_CLOSED;
    if (isOwnListing || isCurrentlyClosed) {
      window.scrollTo(0, 0);
    } else {
      onSubmit({
        ...values,
        variationValues: variationValuesToDisplay.join(', '),
        storeName: storeListingInformation.attributes.title,
        productTaxCode,
        variationSKU,
        checkoutType: CHECKOUT_TYPE.SINGLE_PRODUCT,
      });
    }
  };

  const facebookImages = listingImages(currentListing, 'facebook');
  const twitterImages = listingImages(currentListing, 'twitter');
  const schemaImages = listingImages(
    currentListing,
    `${config.layout.listingImage.variantPrefix}-2x`
  ).map(img => img.url);
  const marketplaceName = config.marketplaceName;
  const schemaTitle = intl.formatMessage(
    { id: 'ListingPage.schemaTitle' },
    { title }
  );
  // You could add reviews, sku, etc. into page schema
  // Read more about product schema
  // https://developers.google.com/search/docs/advanced/structured-data/product
  const productURL = `${config.marketplaceRootURL}${location.pathname}${location.search}${location.hash}`;

  const schemaPriceMaybe = price
    ? {
        price: intl.formatNumber(convertMoneyToNumber(price), {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        }),
        priceCurrency: price.currency,
      }
    : {};

  const schemaAvailability = `[http://schema.org/InStock](http://schema.org/InStock "smartCard-inline")`;
  const schemaReviews = reviews.reduce((res, currentReview) => {
    const { review, rating, dateReviewed, displayName } = currentReview;

    res.push({
      '@type': 'Review',
      reviewBody: review,
      reviewRating: {
        '@type': 'Rating',
        ratingValue: rating,
      },
      author: {
        '@type': 'Person',
        name: displayName,
      },
      datePublished: stringifyDateToISO8601(dateReviewed),
    });

    return res;
  }, []);

  const createFilterOptions = options =>
    options.map(o => ({ key: `${o.option}`, label: o.label }));

  const tabs = [
    {
      label: intl.formatMessage({ id: 'ListingPage.reviewsTab' }),
      tabName: REVIEWS,
      total: totalReviews,
      component: (
        <SectionReviews
          reviews={reviews}
          fetchReviewsError={fetchReviewsError}
          fetchReviewsInprogress={fetchReviewsInprogress}
          totalReviews={totalReviews}
          onFetchMoreReviews={onFetchMoreReviews}
        />
      ),
    },
  ];
  const QualityStatement = () => {
    return (
      <div className={css.qualitymessages}>
        <p>
          <strong>100% Happiness Guaranteed!</strong>
        </p>
        <p className={css.qualitymessage}>
          <span className={`${css.icon} material-symbols-outlined`}>lock</span>
          <span>Shop with Confidence</span>
        </p>
        <p className={css.qualitymessage}>
          <span className={`${css.icon} material-symbols-outlined`}>check</span>
          <span>Easy 30 day returns!</span>
        </p>
      </div>
    );
  };

  return (
    <Page
      title={schemaTitle}
      scrollingDisabled={scrollingDisabled}
      author={authorDisplayName}
      description={description}
      facebookImages={facebookImages}
      twitterImages={twitterImages}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'Product',
        name: schemaTitle,
        description: description,
        sku,
        brand: {
          '@type': 'Brand',
          name: ensuredAuthor.attributes.profile.publicData?.fullName,
        },
        offers: {
          '@type': 'Offer',
          ...schemaPriceMaybe,
          availability: schemaAvailability,
          url: `[${productURL}](${productURL})`,
          seller: {
            '@type': 'Organization',
            name: storeName,
          },
        },
        review: schemaReviews,
      }}
      isScrollToTopAfterCloseModalInMobile
    >
      <LayoutSingleColumn
        className={css.pageRoot}
        topbar={topbar}
        footer={<FooterContainer />}
      >
        <div className={css.contentWrapperForProductLayout}>
          <div className={css.mainColumnForProductLayout}>
            {currentListing.id ? (
              <ActionBarMaybe
                className={css.actionBarForProductLayout}
                isOwnListing={isOwnListing}
                listing={currentListing}
                editParams={{
                  id: listingId.uuid,
                  slug: listingSlug,
                  type: listingPathParamType,
                  tab: listingTab,
                }}
                pageName="EditListingPage"
                listingType={publicData.listingType}
              />
            ) : null}
            <SectionGallery
              listing={currentListing}
              variantPrefix={config.layout.listingImage.variantPrefix}
            />
            <div className={css.mobileHeading}>
              <H4 as="h1" className={css.orderPanelTitle}>
                <FormattedMessage
                  id="ListingPage.orderTitle"
                  values={{ title: richTitle }}
                />
              </H4>

              {/* Store module  */}
              {fetchMakerListingInProgress ? (
                <IconSpinner />
              ) : (
                <div className={css.author}>
                  <Avatar
                    customImage={makerListing ? makerImage : storeImage}
                    className={css.providerAvatar}
                    customLinkProps={linkProps}
                  />
                  <span className={css.providerNameLinked}>
                    <NamedLink className={css.authorNameLink} {...linkProps}>
                      {makerListing ? makerName : storeName}
                    </NamedLink>
                  </span>
                </div>
              )}
            </div>

            <SectionTextMaybe text={description} showAsIngress />
            <SectionBullets
              key="productWeight"
              heading="Product Weight"
              text={productWeightTextValues}
            />
            {listingConfig.listingFields.reduce((pickedElements, config) => {
              const { key, enumOptions, scope = 'public' } = config;
              const value =
                scope === 'public'
                  ? publicData[key]
                  : scope === 'metadata'
                  ? metadata[key]
                  : null;
              const hasValue = value !== null;

              if (!hasValue) {
                return null;
              }
              if (config.schemaType === SCHEMA_TYPE_MULTI_ENUM) {
                return [
                  ...pickedElements,
                  <SectionMultiEnumMaybe
                    key={key}
                    heading={config?.showConfig?.label}
                    options={createFilterOptions(enumOptions)}
                    selectedOptions={value}
                  />,
                ];
              }
              if (key == 'makerName') {
                return [...pickedElements];
              }
              if (key == 'weightUnit') {
                return [...pickedElements];
              }
              if (key == 'weight') {
                return [...pickedElements];
              }
              if (key == 'dimSize') {
                return [...pickedElements];
              }
              if (key == 'productHighlights') {
                return [
                  ...pickedElements,
                  <SectionBullets
                    key={key}
                    // heading={config?.showConfig?.label}
                    heading="Product Features"
                    text={value}
                  />,
                ];
              }
              return [
                ...pickedElements,
                <SectionTextMaybe
                  key={key}
                  heading={config?.showConfig?.label}
                  text={value}
                />,
              ];
            }, [])}
            {/*<SectionDynamicFields publicData={publicData} config={config} />*/}

            <SectionMapMaybe
              geolocation={geolocation}
              publicData={publicData}
              listingId={currentListing.id}
              mapsConfig={config.maps}
            />
            {false && (
              <SectionShippingInformation publicData={publicData} intl={intl} />
            )}
            <div className={css.tabs}>
              {tabs.map(tab => {
                if (tab.tabName === REVIEWS && isEmpty(reviews)) {
                  return null;
                }

                const tabClasses = classNames(css.tab, {
                  [css.selectedTab]: tab.tabName === selectedTab,
                });
                return (
                  <p key={tab.tabName} className={tabClasses}>
                    {tab.label}
                    <span className={css.tabIndex}>{tab.total} </span>
                  </p>
                );
              })}
            </div>
            {tabs.find(tab => tab.tabName === selectedTab)?.component}

            <SectionAuthorMaybe
              title={title}
              listing={currentListing}
              authorDisplayName={storeName}
              onContactUser={onContactUser}
              isInquiryModalOpen={isAuthenticated && inquiryModalOpen}
              onCloseInquiryModal={() => setInquiryModalOpen(false)}
              sendInquiryError={sendInquiryError}
              sendInquiryInProgress={sendInquiryInProgress}
              onSubmitInquiry={onSubmitInquiry}
              currentUser={currentUser}
              onManageDisableScrolling={onManageDisableScrolling}
              customImage={storeImage}
              customLinkProps={storeCustomLinkProps}
              makerListing={makerListing}
              fetchMakerListingInProgress={fetchMakerListingInProgress}
              fetchMakerListingError={fetchMakerListingError}
            />
            <SectionTags tags={publicData.tags} />

            <QualityStatement />
          </div>
          <div className={css.orderColumnForProductLayout}>
            <OrderPanel
              className={css.productOrderPanel}
              listing={currentListing}
              isOwnListing={isOwnListing}
              onSubmit={handleOrderSubmit}
              title={
                <FormattedMessage
                  id="ListingPage.orderTitle"
                  values={{ title: richTitle }}
                />
              }
              titleDesktop={
                <H4 as="h1" className={css.orderPanelTitle}>
                  <FormattedMessage
                    id="ListingPage.orderTitle"
                    values={{ title: richTitle }}
                  />
                </H4>
              }
              author={ensuredAuthor}
              onManageDisableScrolling={onManageDisableScrolling}
              onContactUser={onContactUser}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              validListingTypes={config.listing.listingTypes}
              marketplaceCurrency={config.currency}
              dayCountAvailableForBooking={
                config.stripe.dayCountAvailableForBooking
              }
              marketplaceName={config.marketplaceName}
              productAttributes={productAttributes}
              customLinkProps={storeCustomLinkProps}
              makerListing={makerListing}
              fetchMakerListingInProgress={fetchMakerListingInProgress}
              fetchMakerListingError={fetchMakerListingError}
              productVariationSelected={productVariationSelected}
              setProductVariationSelected={setProductVariationSelected}
            />
          </div>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

ListingPageComponent.defaultProps = {
  currentUser: null,
  inquiryModalOpenForListingId: null,
  showListingError: null,
  reviews: [],
  fetchReviewsError: null,
  fetchReviewsInprogress: false,
  monthlyTimeSlots: null,
  sendInquiryError: null,
  listingConfig: null,
  lineItems: null,
  fetchLineItemsError: null,
};

ListingPageComponent.propTypes = {
  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,
  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,

  params: shape({
    id: string,
    slug: string,
    variant: oneOf([
      LISTING_PAGE_DRAFT_VARIANT,
      LISTING_PAGE_PENDING_APPROVAL_VARIANT,
    ]),
  }).isRequired,

  isAuthenticated: bool.isRequired,
  currentUser: propTypes.currentUser,
  getListing: func.isRequired,
  getOwnListing: func.isRequired,
  onManageDisableScrolling: func.isRequired,
  scrollingDisabled: bool.isRequired,
  inquiryModalOpenForListingId: string,
  showListingError: propTypes.error,
  callSetInitialValues: func.isRequired,
  reviews: array,
  fetchReviewsInprogress: bool,
  fetchReviewsError: propTypes.error,
  monthlyTimeSlots: object,
  // monthlyTimeSlots could be something like:
  // monthlyTimeSlots: {
  //   '2019-11': {
  //     timeSlots: [],
  //     fetchTimeSlotsInProgress: false,
  //     fetchTimeSlotsError: null,
  //   }
  // }
  sendInquiryInProgress: bool.isRequired,
  sendInquiryError: propTypes.error,
  onSendInquiry: func.isRequired,
  onInitializeCardPaymentData: func.isRequired,
  listingConfig: object,
  onFetchTransactionLineItems: func.isRequired,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
};

const EnhancedListingPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  return (
    <ListingPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...props}
    />
  );
};

const mapStateToProps = state => {
  const { isAuthenticated } = state.auth;
  const {
    showListingError,
    reviews,
    fetchReviewsError,
    fetchReviewsInprogress,
    monthlyTimeSlots,
    sendInquiryInProgress,
    sendInquiryError,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    inquiryModalOpenForListingId,
    productAttributes,
    id,
  } = state.ListingPage;
  const { currentUser } = state.user;

  const getListing = id => {
    const ref = { id, type: 'listing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  const getOwnListing = id => {
    const ref = { id, type: 'ownListing' };
    const listings = getMarketplaceEntities(state, [ref]);
    return listings.length === 1 ? listings[0] : null;
  };

  return {
    isAuthenticated,
    currentUser,
    getListing,
    getOwnListing,
    scrollingDisabled: isScrollingDisabled(state),
    inquiryModalOpenForListingId,
    showListingError,
    reviews,
    fetchReviewsError,
    fetchReviewsInprogress,
    monthlyTimeSlots,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    sendInquiryInProgress,
    sendInquiryError,
    productAttributes,
    listingId: new UUID(id),
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
  callSetInitialValues: (setInitialValues, values, saveToSessionStorage) =>
    dispatch(setInitialValues(values, saveToSessionStorage)),
  onFetchTransactionLineItems: params =>
    dispatch(fetchTransactionLineItems(params)),
  onSendInquiry: (listing, message) => dispatch(sendInquiry(listing, message)),
  onInitializeCardPaymentData: () => dispatch(initializeCardPaymentData()),
  onFetchTimeSlots: (listingId, start, end, timeZone) =>
    dispatch(fetchTimeSlots(listingId, start, end, timeZone)),
  onFetchMoreReviews: () => dispatch(fetchReviews()),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const ListingPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedListingPage);

export default ListingPage;
