import React from 'react';
import { NamedLink } from '../../components';
import { createSlug } from '../../util/urlHelpers';

import css from './ListingPage.module.css';

const SectionTags = props => {
  const { tags } = props;
  return (
    <div className={css.sectionTags}>
      {tags?.split(',')?.map(item => {
        const tagSlug = createSlug(item);
        return (
          // <NamedLink
          //   key={tagSlug}
          //   name="CloudTagPage"
          //   params={{ cloudTag: tagSlug }}
          //   className={`${css.tagItem} button button-white button-animate button-animate-offwhite`}
          // >
          //   {item.trim()}
          // </NamedLink>
          <div key={tagSlug} className={`${css.tagItem} button button-white`}>
            {item.trim()}
          </div>
        );
      })}
    </div>
  );
};

export default SectionTags;
