import React from "react";
import css from "../BecomeASellerStaticPage.module.css";

const BottomBar = props => {
  const { onOpenGatewayDialog } = props;

  const handleClickOpenGateWayDialog = e => {
    e.preventDefault();
    onOpenGatewayDialog(true);
  }

  return (
    <div id={css['bottombar']} className={css.container}>
      <div>
        <h6>Are you ready?</h6>
        <a
          href="#"
          onClick={handleClickOpenGateWayDialog}
          className={`${css.button} ${css['button-dark']} ${
            css['button-animate']
          } ${css['button-animate-dark']} ${css.fixedwidth} ${css.dialog}`}
          data-dialogtype="gateway"
        >
          Join the list
          <span
            className={`${css.icon} material-symbols-outlined ${
              css['c-white']
            }`}
          >
            arrow_right_alt
          </span>
        </a>
      </div>
    </div>
  );
};

export default BottomBar;