// ReactImageFullScreenGallery.jsx

import React, { useState } from 'react';
import css from './ListingImageFullScreenGallery.module.css';
import { Modal, IconArrowHead } from '../../../components';

const ListingImageFullScreenGallery = ({
  imageItems,
  isModalOpen,
  closeModal = () => {},
}) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const selectImage = (e, index) => {
    e.preventDefault();
    setSelectedImageIndex(index);
  };

  const navigateImages = direction => {
    const newIndex =
      direction === 'next'
        ? (selectedImageIndex + 1) % imageItems.length
        : (selectedImageIndex - 1 + imageItems.length) % imageItems.length;
    setSelectedImageIndex(newIndex);
  };

  return (
    <div>
      {/* {button && React.cloneElement(button, { onClick: openModal })} */}
      <Modal
        id="ListingCard.listingZoomIcon"
        isOpen={isModalOpen}
        onClose={closeModal}
        onManageDisableScrolling={() => {}}
        containerClassName={css.modalContainer}
        usePortal={false}
      >
        <div className={css['image-gallery-container']}>
          <button
            className={`${css['arrow-button']} ${css.navLeft}`}
            onClick={e => {
              e.preventDefault();
              navigateImages('prev');
            }}
          >
            <IconArrowHead direction="left" size="small" />
          </button>
          <div className={css['selected-image-wrapper']}>
            <img
              className={css.selectedimage}
              src={imageItems[selectedImageIndex]}
              alt={`Image ${selectedImageIndex + 1}`}
            />
          </div>
          <button
            className={`${css['arrow-button']} ${css.navRight}`}
            onClick={e => {
              e.preventDefault();
              navigateImages('next');
            }}
          >
            <IconArrowHead direction="right" size="small" />
          </button>
        </div>
        <div className={css['thumbnail-container']}>
          {imageItems.map((imageUrl, index) => (
            <div 
              key={index}
              className={`${css['thumbnail-image']} ${selectedImageIndex === index ? css.active : null}`}
            >
              <img                
                className={css['thumb']}
                src={imageUrl}
                alt={`Thumbnail ${index + 1}`}
                onClick={e => selectImage(e, index)}
              />
            </div>
          ))}
        </div>
      </Modal>
    </div>
  );
};

export default ListingImageFullScreenGallery;
