import React from 'react';
import { Heading } from '../../components';
import { richText } from '../../util/richText';

import css from './ListingPage.module.css';
import _ from 'lodash';
const MIN_LENGTH_FOR_LONG_WORDS = 20;

const SectionBullets = props => {
  const { text, heading, showAsIngress = false } = props;
  const textClass = showAsIngress ? css.ingress : css.text;
  // const content = richText(text, {
  //   longWordMinLength: MIN_LENGTH_FOR_LONG_WORDS,
  //   longWordClass: css.longWord,
  //   breakChars: '/',
  // });
  const content = _.split(text, ',');
  return text ? (
    <div className={css.sectionText}>
      {heading ? (
        <Heading as="h2" rootClassName={css.sectionHeading}>
          {heading}
        </Heading>
      ) : null}
      <ul className={css.ulItem}>
        {_.map(content, (item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
    </div>
  ) : null;
};

export default SectionBullets;
