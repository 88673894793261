import React from 'react';

import css from '../BecomeASellerStaticPage.module.css';

const BecomeASellerSignupSection = props => {
  const { onOpenSellerForm, onOpenBuyerForm } = props;

  const handleOpenSellerForm = e => {
    e.preventDefault();
    onOpenSellerForm(true);
  }

  const handleOpenBuyerForm = e => {
    e.preventDefault();
    onOpenBuyerForm(true);
  }

  return (
    <section id={css['signup']}>
      <div className={css['signup-content']}>
        <div id={css['signup-heading']}>
          <h4>
            Join the handmade movement{' '}
            <span className={css['c-highlight1']}>today</span>
          </h4>
        </div>
        <div id={css['signup-sellers']}>
          <h6 className={css['c-highlight1']}>Sellers</h6>
          <p>
            <button
              type="submit"
              onClick={handleOpenSellerForm}
              className={`${css.button} ${css['button-highlight1']} ${
                css['button-animate']
              } ${css['button-animate-dark']} ${css['fixedwidth']} ${
                css.dialog
              }`}
              data-dialogtype="sellers"
            >
              Become a seller
              <span
                className={`${css.icon} material-symbols-outlined ${
                  css['c-white']
                }`}
              >
                arrow_right_alt
              </span>
            </button>
          </p>
        </div>
        <div id={css['signup-buyers']}>
          <h6 className={css['c-highlight3']}>Buyers</h6>
          <p>
            <button
              type="submit"
              onClick={handleOpenBuyerForm}
              className={`${css.button} ${css['button-highlight3']} ${
                css['button-animate']
              } ${css['button-animate-dark']} ${css['fixedwidth']} ${
                css.dialog
              }`}
              data-dialogtype="buyers"
            >
              Join today
              <span
                className={`${css.icon} material-symbols-outlined ${
                  css['c-white']
                }`}
              >
                arrow_right_alt
              </span>
            </button>
          </p>
        </div>
      </div>
    </section>
  );
};

export default BecomeASellerSignupSection;
