import { array, bool, func, number, object } from 'prop-types';
import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { CustomReviews, H3, InlineTextButton } from '../../components';
import Spinner from '../../components/IconSpinner/IconSpinner';
import { FormattedMessage } from '../../util/reactIntl';

import css from './ListingPage.module.css';

const SECTION_REVIEWS = 'reviews';

const SectionReviews = props => {
  const {
    reviews,
    fetchReviewsError,
    fetchReviewsInProgress,
    totalReviews,
    onFetchMoreReviews,
  } = props;
  const isShowMore =
    totalReviews > reviews.length &&
    !fetchReviewsInProgress &&
    reviews.length > 0;

  if (isEmpty(reviews)) return null;

  return (
    <section id={SECTION_REVIEWS} className={css.sectionReviews}>
      {fetchReviewsError ? (
        <H3 className={css.errorText}>
          <FormattedMessage id="ListingPage.reviewsError" />
        </H3>
      ) : null}
      <CustomReviews reviews={reviews} />
      {isShowMore && (
        <div className={css.buttonWrapper}>
          <InlineTextButton
            className={css.showMore}
            onClick={onFetchMoreReviews}
          >
            <FormattedMessage id="ListingPage.showMoreReviews" />
          </InlineTextButton>
        </div>
      )}
      {fetchReviewsInProgress ? <Spinner className={css.spinner} /> : null}
    </section>
  );
};

SectionReviews.propTypes = {
  reviews: array,
  fetchReviewsError: object,
  fetchReviewsInProgress: bool,
  totalReviews: number,
  onFetchMoreReviews: func,
};

export default SectionReviews;
