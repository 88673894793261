import React, { useEffect, useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';

import css from '../BecomeASellerStaticPage.module.css';

const Overlay = props => {
  const {
    children,
    onOpenGatewayDialog,
    isOpenSellerForm,
    shouldShowLoader = false,
  } = props;
  const ref = useRef(null);

  useLayoutEffect(() => {
    let exitLeftTimeout;
    let endOfShowTimeout;

    if (shouldShowLoader && ref.current) {
      ref.current.classList.add(css['inplace']);
      exitLeftTimeout = setTimeout(() => {
        ref.current.classList.remove(css['inplace']);
        ref.current.classList.add(css['stageleft']);
        endOfShowTimeout = setTimeout(() => {
          ref.current.classList.remove(css['stageleft']);
          ref.current.classList.add(css['finished']);
        }, 1000);
      }, 1500);
    }

    return () => {
      clearTimeout(exitLeftTimeout);
      clearTimeout(endOfShowTimeout);
    };
  }, []);

  useEffect(() => {
    if (!shouldShowLoader && ref.current) {
      ref.current.classList.add(css['finished']);
    }
  }, []);

  const handleCloseDialog = e => {
    e.preventDefault();
    onOpenGatewayDialog(false);
  };
  return (
    <div ref={ref} id={css.overlay}>
      <div className={css.logo}>
        <img src="/static/images/logo/main.png" id={css['main-logo']} />
      </div>
      <div className={css['intro-tagline']}>
        A marketplace <span className={css['c-highlight1']}>like no other</span>
      </div>
      <div
        id={css.dialog}
        className={classNames({ [css.sellers]: isOpenSellerForm })}
      >
        <a href="#" onClick={handleCloseDialog} className={css['close-dialog']}>
          <span className="material-symbols-outlined">close</span>
        </a>
        <div className={css.content}>{children}</div>
      </div>
    </div>
  );
};

export default Overlay;
