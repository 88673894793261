import React from "react";
import { useIntl } from "react-intl";

const SellerForm = () => {
  const intl = useIntl();
  const typeFormURL = intl.formatMessage({
    id: 'Topbar.sellerRegistrationFormLink',
  });
  return <iframe src={typeFormURL} allowFullScreen />;
};

export default SellerForm;