import axios from "axios";

const axiosInstance = axios.create({
  baseURL: 'https://www.novica.com/2015',
  headers: {
    Accept: 'application/json',
    'Content-type': 'text/plain;charset=UTF-8',
  },
});

export const submitBuyerDataToNovica = body => {
  return axiosInstance.post(
    `/?action=remote.logNewBuyers&requestid=${new Date().getTime()}`,
    body
  );
};
