import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { LayoutSingleColumn, NamedLink, Page } from '../../components';

import Header from './components/HeaderContainer';
import Footer from './components/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import BottomBar from './components/BottomBar';
import Overlay from './components/Overlay';
import SellerForm from './components/SellerForm';
import BuyerSignupForm from './components/BuyerSignupForm';
import SignupSection from './components/SignupSection';
import BecomeASellerSignupSection from './components/BecomeASellerSignupSection';
import { submitBuyerData } from './BecomeASellerStaticPage.duck';

import css from './BecomeASellerStaticPage.module.css';

const VillageCouncilSections = () => {
  return (
    <>
      <section
        id={css['village-council-intro']}
        className={`${css['container']} ${css['full']}`}
      >
        <div className={`${css['contentblock']} ${css.block1}`}>
          <div className={css.text1}>
            <h1>
              Village <span className={`${css['c-highlight1']}`}>Council</span>
            </h1>
          </div>
          <div className={`${css['imageblock']}`}>
            <div className={`${css['imagegroup']}`}>
              <div className={`${css.image} ${css['image1']}`}>
                <img
                  src="/static/images/village-council/section1-image1-new.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css['image2']}`}>
                <img
                  src="/static/images/village-council/section1-image2.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css['image3']}`}>
                <img
                  src="/static/images/village-council/section1-image3.jpg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={css['transparency-representation']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3}`}>
          <div className={css.text}>
            <h4>
              Transparency. Fairness.{' '}
              <span className={`${css['c-highlight1']}`}>Representation.</span>
            </h4>
            <p>
              Marketplaces around the world are where people gather, shop,
              debate, share news, tell stories, and discuss the most important
              events of the day. Ever-evolving, ever-in-flux, marketplaces are
              not static entities, but dynamic spaces that reflect the changing
              hopes, needs, concerns, and aspirations of those who frequent
              them.
            </p>
          </div>
        </div>
      </section>

      <section
        id={css['the-same-is-true']}
        className={`${css.container} ${css.full} `}
      >
        <div className={`${css.contentblock} ${css.block1} `}>
          <div className={css.text}>
            <h3>
              The same is true for
              <span className={`${css['c-highlight1']} ${css.underline}`}>
                Handmade.com.
                <span className={css.underline}>
                  <img src="/static/images/village-council/underline-highlight1.png" />
                </span>
              </span>
            </h3>
            <p>
              To encourage a truly vibrant community, we believe that artisans
              must have an important seat at the table. Artisan voices must help
              guide the conversation and shape the experience of our
              marketplace, which is always growing and changing. This is where
              the Village Council comes in.
            </p>
          </div>
          <div className={`${css['imageblock']}`}>
            <div className={`${css.image} ${css['image1']}`}>
              <img
                src="/static/images/village-council/section3-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id="council-of-elders"
        className={`${css.container} ${css.full} `}
      >
        <div className={`${css.contentblock} ${css.block3} `}>
          <div className={css.text}>
            <p>
              The Village Council serves as a kind of Council of Elders, a
              15-person body of elected master artisans representing diverse
              geographic regions of the world, two members from Handmade.com,
              and one member from a prestigious international arts organization.
              Their role is to help oversee Handmade.com’s growth, while
              safeguarding its mission. As representatives of our artisan-seller
              community, they not only share their technical expertise, but also
              their perspectives on the needs and challenges that artisans face,
              particularly as Handmade.com continues to expand. They contribute
              insights, engage in high-level discussions, and help ensure that
              transparency, fairness and representation remain always at the
              forefront of our marketplace experience.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

const VillageCouncilStaticPage = () => {
  // const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenGatewayDialog, setIsOpenGatewayDialog] = useState(false);
  const [isShowSellerForm, setIsShowSellerForm] = useState(false);
  const [isShowBuyerForm, setIsShowBuyerForm] = useState(false);

  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  useEffect(() => {
    if (!isOpenGatewayDialog) {
      setIsShowSellerForm(false);
      setIsShowBuyerForm(false);
    }
  }, [isOpenGatewayDialog]);

  useEffect(() => {
    if (isShowSellerForm || isShowBuyerForm) {
      setIsOpenGatewayDialog(true);
    }
  }, [isShowSellerForm, isShowBuyerForm]);

  const intl = useIntl();

  const dispatch = useDispatch();
  const onSubmitBuyerData = values => dispatch(submitBuyerData(values));

  const handleSubmitBuyerData = values => {
    return onSubmitBuyerData(values);
  };

  const shouldOpenDialog = isOpenGatewayDialog;

  const title = intl.formatMessage({ id: 'BecomeASellerStaticPage.title' });

  return (
    <Page
      className={css.staticPageBase}
      title={title}
      scrollingDisabled={shouldOpenDialog || scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterComponent />}
      >
        <div
          className={classNames(css.handmadecontent, {
            [css['dialog-open']]: shouldOpenDialog,
          })}
        >
          <div id={css['main-canvas']} className={css.container}>
            <div id={css['main-content']}>
              <VillageCouncilSections />
            </div>
            <BecomeASellerSignupSection
              onOpenSellerForm={setIsShowSellerForm}
              onOpenBuyerForm={setIsShowBuyerForm}
            />
          </div>
          <BottomBar onOpenGatewayDialog={setIsOpenGatewayDialog} />
          <Overlay
            isOpenSellerForm={isShowSellerForm}
            onOpenGatewayDialog={setIsOpenGatewayDialog}
          >
            {isShowSellerForm || isShowBuyerForm ? (
              isShowSellerForm ? (
                <SellerForm />
              ) : (
                <BuyerSignupForm onSubmit={handleSubmitBuyerData} />
              )
            ) : (
              isOpenGatewayDialog && (
                <SignupSection
                  onOpenSellerForm={setIsShowSellerForm}
                  onOpenBuyerForm={setIsShowBuyerForm}
                />
              )
            )}
          </Overlay>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default VillageCouncilStaticPage;
