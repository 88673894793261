import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { LayoutSingleColumn, NamedLink, Page } from '../../components';

import Header from './components/HeaderContainer';
import Footer from './components/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import BottomBar from './components/BottomBar';
import Overlay from './components/Overlay';
import SellerForm from './components/SellerForm';
import BuyerSignupForm from './components/BuyerSignupForm';
import SignupSection from './components/SignupSection';
import BecomeASellerSignupSection from './components/BecomeASellerSignupSection';
import { submitBuyerData } from './BecomeASellerStaticPage.duck';

import css from './BecomeASellerStaticPage.module.css';

const OurCommitmentSections = () => {
  return (
    <>
      <section
        id={css['our-commitment-intro']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css['text1']}>
            <h1>
              Our <span className={css['c-highlight3']}>commitment</span>
            </h1>
          </div>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/our-commitment/section1-image1-new.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id={css['stewards-of-handmade']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3}`}>
          <div className={css['text1']}>
            <h4>
              Stewards of <span className={css['c-highlight1']}>handmade</span>
            </h4>
            <p>
              Our dream is to create a community that stands up for handmade.
              Everyone is welcome - buyers, sellers, and anyone who cares about
              handmade. Why? The world is changing in the blink of an eye.
              Technology and innovation deliver new possibilities every single
              day. But culture, heritage and time-honored crafts are often
              caught in the crosshairs. If we don't act, many crafts run the
              risk of disappearing entirely.
            </p>
          </div>
        </div>
      </section>

      <section
        id={css['we-promise-to-do']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block2} ${css.spaceright}`}>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/our-commitment/section3-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css['text']}>
            <p>
              We promise to do everything within our power to protect our
              vibrant cultural heritage by bringing together incredible creators
              and dedicated customers. Our job is to create a global marketplace for the highest-quality handmade items.
            </p>
            <p>
              We are committed to ethically-crafted, environmentally-conscious,
              people-first practices. And we strive for authenticity and
              transparency as we work to ensure that handmade crafts live to see
              another day.
            </p>
          </div>
        </div>
      </section>

      <section
        id={css['living-the-movement']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css['text']}>
            <h3>
              Living the <span className={css['c-highlight1']}>movement.</span>
            </h3>
            <p>
              Handmade is our passion. It is not simply a buzz word or a passing
              trend. To live by the principles of handmade is to stand up for
              the values of cultural preservation, time-honored crafts, ethical
              practices, and the people who dedicate their time and effort to
              creating unique, meaningful works of art. Our intention is to
              always represent these values in the most authentic way possible.
            </p>
          </div>
          <div className={css['imageblock']}>
            <div className={css['imagegroup']}>
              <div className={`${css.image} ${css.image1}`}>
                <img
                  src="/static/images/our-commitment/section4-image1.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css.image2}`}>
                <img
                  src="/static/images/our-commitment/section4-image2.jpg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section
        id={css['happy-sellers']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block2} ${css.spaceright}`}>
          <div className={css['text']}>
            <h4>
              Happy <span className={css['c-highlight3']}>sellers</span>
            </h4>
            <p>
              Our platform was built with sellers in mind. Every decision we
              make begins with the question: “is this good for artisans?” While
              we recognize that every seller has unique needs, our goal is
              always to listen, learn, and embrace practices that increasingly
              support our seller community. At Handmade.com, artisans have a
              seat at the table and can always apply to participate in our
              Handmade Corps or be elected by the seller community to the
              Village Council.
            </p>
          </div>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/our-commitment/section5-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>

      <section
        id={css['happy-buyers']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/our-commitment/section6-image1-new.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css['text']}>
            <h4>
              Happy <span className={css['c-highlight3']}>buyers</span>
            </h4>
            <p>
              None of this would be possible without our incredible buyers.
              Every purchase helps to ensure that handmade crafts persevere. Our
              goal is to showcase a curated collection of the world’s best artisans to delight, surprise, and introduce you to beautiful
              creations that add meaning and joy to your life.
            </p>
            <p>
              As our marketplace continues to grow, we rely on our vibrant buyer
              community to play an integral role in creating the best possible
              experience for everyone who loves and supports handmade crafts.We
              will seek your feedback, unique insights, and firsthand
              experiencesto ensure that this marketplace lives up to the highest
              standards.
            </p>
          </div>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image2}`}>
              <img
                src="/static/images/our-commitment/section6-image2-new.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const OurCommitmentStaticPage = () => {
  // const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenGatewayDialog, setIsOpenGatewayDialog] = useState(false);
  const [isShowSellerForm, setIsShowSellerForm] = useState(false);
  const [isShowBuyerForm, setIsShowBuyerForm] = useState(false);

  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  useEffect(() => {
    if (!isOpenGatewayDialog) {
      setIsShowSellerForm(false);
      setIsShowBuyerForm(false);
    }
  }, [isOpenGatewayDialog]);

  useEffect(() => {
    if (isShowSellerForm || isShowBuyerForm) {
      setIsOpenGatewayDialog(true);
    }
  }, [isShowSellerForm, isShowBuyerForm]);

  const intl = useIntl();

  const dispatch = useDispatch();
  const onSubmitBuyerData = values => dispatch(submitBuyerData(values));

  const handleSubmitBuyerData = values => {
    return onSubmitBuyerData(values);
  };

  const shouldOpenDialog = isOpenGatewayDialog;

  const title = intl.formatMessage({ id: 'BecomeASellerStaticPage.title' });

  return (
    <Page
      className={css.staticPageBase}
      title={title}
      scrollingDisabled={shouldOpenDialog || scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterComponent />}
      >
        <div
          className={classNames(css.handmadecontent, {
            [css['dialog-open']]: shouldOpenDialog,
          })}
        >
          <div id={css['main-canvas']} className={css.container}>
            <div id={css['main-content']}>
              <OurCommitmentSections />
            </div>
            <BecomeASellerSignupSection
              onOpenSellerForm={setIsShowSellerForm}
              onOpenBuyerForm={setIsShowBuyerForm}
            />
          </div>
          <BottomBar onOpenGatewayDialog={setIsOpenGatewayDialog} />
          <Overlay
            isOpenSellerForm={isShowSellerForm}
            onOpenGatewayDialog={setIsOpenGatewayDialog}
          >
            {isShowSellerForm || isShowBuyerForm ? (
              isShowSellerForm ? (
                <SellerForm />
              ) : (
                <BuyerSignupForm onSubmit={handleSubmitBuyerData} />
              )
            ) : (
              isOpenGatewayDialog && (
                <SignupSection
                  onOpenSellerForm={setIsShowSellerForm}
                  onOpenBuyerForm={setIsShowBuyerForm}
                />
              )
            )}
          </Overlay>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default OurCommitmentStaticPage;