import React, { useState } from 'react';
import { arrayOf, number } from 'prop-types';

import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import ListingCard from '../../components/ListingCard/ListingCard';
import ListingImageFullScreenGallery from '../ListingPage/ListingImageFullScreenGallery/ListingImageFullScreenGallery';
import css from './StoreListingPage.module.css';

const SectionListings = props => {
  const [largePicModalOptions, setLargePicModalOptions] = useState({
    isModalOpen: false,
    imageItems: [],
  });
  const {
    intl,
    listingNumber,
    listings,
  } = props;

  const openLargePicModal = imageItems => {
    setLargePicModalOptions({
      isModalOpen: true,
      imageItems,
    });
  };

  return (
    <div className={css.sectionListingsContainer}>
      <h2 className={css.sectionListingsTitle}>
        {intl.formatMessage(
          { id: 'StoreListingPage.SectionListings.title' },
          { listingNumber }
        )}
      </h2>
      <div className={`${css.listingsContainer} categoryview list shortername`}>
        {listings.map(l => (
          <ListingCard
            key={l.id.uuid}
            className={`${css.listingCard} box`}
            listing={l}
            showZoomIcon={true}
            openLargePicModal={openLargePicModal}
          />
        ))}
      </div>
      <ListingImageFullScreenGallery
        imageItems={largePicModalOptions.imageItems}
        isModalOpen={largePicModalOptions.isModalOpen}
        closeModal={() =>
          setLargePicModalOptions({
            isModalOpen: false,
            imageItems: [],
          })
        }
      />
    </div>
  );
};

SectionListings.defaultProps = {
  listings: [],
};

SectionListings.propTypes = {
  intl: intlShape.isRequired,
  listingNumber: number,
  listings: arrayOf(propTypes.listing),
};

export default injectIntl(SectionListings);
