import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { LayoutSingleColumn, NamedLink, Page } from '../../components';

import Header from './components/HeaderContainer';
import Footer from './components/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import BottomBar from './components/BottomBar';
import Overlay from './components/Overlay';
import SellerForm from './components/SellerForm';
import BuyerSignupForm from './components/BuyerSignupForm';
import SignupSection from './components/SignupSection';
import BecomeASellerSignupSection from './components/BecomeASellerSignupSection';
import { submitBuyerData } from './BecomeASellerStaticPage.duck';

import css from './BecomeASellerStaticPage.module.css';

const TheMovementSections = () => {
  return (
    <>
      <section
        id={css['the-movement-intro']}
        className={`${css['container']} ${css['full']}`}
      >
        <div className={`${css['contentblock']} ${css.block1}`}>
          <div className={css.text}>
            <h1>
              The <span className={css['c-highlight1']}>handmade</span> movement
            </h1>
          </div>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/the-movement/section1-image1-new.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h5>
              <span className={css['block']}>
                There is a quiet revolution sweeping the world.{' '}
              </span>
              <span className={css['block']}>You may have noticed. </span>
              <span className={css['block']}>In almost every store.</span>
            </h5>
            <p>
              Every refrigerator, restaurant, closet, winery, bakery, beauty
              counter, service provider. It's right there, boldly on the label:
            </p>
            <h4>
              <span className={css['c-highlight1']}>
                Handmade. Artisan-Crafted. Ethically-Made.
              </span>
            </h4>
          </div>
        </div>
      </section>
      <section
        id={css['we-seek-balance']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css['contentblock']} ${css.block1}`}>
          <div className={css.text}>
            <h4>
              <span className={css['c-highlight3']}>Why?</span> Because people
              really do care.
            </h4>
            <p>
              In a world increasingly anonymous and consolidated, we seek
              balance. We crave meaning and authenticity — now more than ever.
            </p>
          </div>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/the-movement/section2-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <p className={css['arrow']}>
              <img src="/static/images/the-movement/arrow-right-highlight3.png" />
            </p>
            <h4>
              Not so long ago,{' '}
              <span className={css['c-highlight3']}>
                most of us were artisans.
              </span>
            </h4>
            <p>
              A hundred years ago? Less? Perhaps, we begin to remember who we
              were. Who we are.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['welcome-home1']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentBlock} ${css.block3}`}>
          <div className={css['text1']}>
            <h3>
              Welcome home, we might start saying to each other.{' '}
              <span className={`${css['c-highlight1']} ${css.underline}`}>
                Welcome home.
                <span className={css['underline']}>
                  <img src="/static/images/the-movement/underline-highlight1.png" />
                </span>
              </span>
            </h3>
          </div>
        </div>
      </section>
      <section
        id={css['handmade-is-a-movement']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css['contentblock']} ${css.block1}`}>
          <div className={css.text}>
            <h3>
              Handmade is a movement of{' '}
              <em className={css['c-highlight1']}>We.</em>
            </h3>
            <p>
              It belongs to all of us. The Handmade Movement is fast outgrowing
              its original niche of artisanal companies and supportive
              customers. We are many, many millions, everywhere. And we’re
              showing our preference for quality, fair trade, transparency and
              authenticity — in every realm of our lives.
            </p>
          </div>
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/the-movement/section4-image1.jpg"
                loading="lazy"
              />
            </div>
            <div className={`${css.image} ${css.image2}`}>
              <img
                src="/static/images/the-movement/section4-image2.jpg"
                loading="lazy"
              />
            </div>
            <div className={`${css.image} ${css.image3}`}>
              <img
                src="/static/images/the-movement/section4-image3-new.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              Handmade is a powerful concept,{' '}
              <span className={css['c-highlight1']}>a belief system.</span>
            </h4>
            <p>
              One that appreciates culture, heritage, nature, and
              accountability. The Handmade Movement is relevant in all aspects
              of our lives, and bridges all lines of work, all cultures, all
              borders. Yet it is apolitical.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['handmade-is-future']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3}`}>
          <div className={`${css.text1}`}>
            <h3>
              Handmade is the{' '}
              <span className={css['c-highlight3']}>alternative</span> to a
              future we won't accept.
            </h3>
            <p>
              Where everything is the same. Everyone looks the same. Dresses the
              same. Eats the same. Where meaning, culture and tradition are
              lost. Handmade is the beautiful, attainable alternative.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['keepers-of-the-arts']}
        className={`${css.container} ${css.full}`}
      >
        <div
          className={[`${css.contentblock} ${css.block2} ${css.spaceright}`]}
        >
          <div className={css['imageblock']}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/the-movement/section6-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              We work hard, every single day, to assist the world's{' '}
              <span className={css['c-highlight1']}>keepers of the arts.</span>
            </h4>
            <p>
              We're thrilled that our impact through Handmade.com and NOVICA has
              already reached millions of customers and tens of thousands of
              artisans and their dependents.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['customers-make-difference']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              To our customers —{' '}
              <span className={css['c-highlight3']}>you make a difference</span>{' '}
              every single time you choose handmade over factory produced.
            </h4>
            <p>
              In every aspect of our lives, each of us encounters handmade
              choices every day: The choice to support our fellow human beings
              and small businesses. The choice to buy or grow organic. The
              choice to invest in meaningful products that endure — and a
              healthier, sustainable, equitable future. Because it's the right
              thing to do.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['joinus-fasten-your-seatbelts']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css['contentblock']} ${css.block1}`}>
          <div className={css['imageblock']}>
            <div className={css['image']}>
              <img
                src="/static/images/the-movement/section8-image1-new.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              <span className={css['c-highlight1']}>Join us.</span> And fasten
              your seatbelts!
            </h4>
            <p>
              The road ahead is filled with tremendous people, gorgeous
              discoveries, and unexpected delights.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['welcome-to-handmade']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css['text1']}>
            <h3>
              Oh, and{' '}
              <span className={`${css['c-highlight1']} ${css.underline}`}>
                welcome home.
                <span className={css['underline']}>
                  <img src="/static/images/the-movement/underline-highlight1.png" />
                </span>
              </span>
            </h3>
          </div>
        </div>
      </section>
    </>
  );
};

const TheMovementStaticPage = () => {
  // const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenGatewayDialog, setIsOpenGatewayDialog] = useState(false);
  const [isShowSellerForm, setIsShowSellerForm] = useState(false);
  const [isShowBuyerForm, setIsShowBuyerForm] = useState(false);

  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  useEffect(() => {
    if (!isOpenGatewayDialog) {
      setIsShowSellerForm(false);
      setIsShowBuyerForm(false);
    }
  }, [isOpenGatewayDialog]);

  useEffect(() => {
    if (isShowSellerForm || isShowBuyerForm) {
      setIsOpenGatewayDialog(true);
    }
  }, [isShowSellerForm, isShowBuyerForm]);

  const intl = useIntl();

  const dispatch = useDispatch();
  const onSubmitBuyerData = values => dispatch(submitBuyerData(values));

  const handleSubmitBuyerData = values => {
    return onSubmitBuyerData(values);
  };

  const shouldOpenDialog = isOpenGatewayDialog;

  const title = intl.formatMessage({ id: 'BecomeASellerStaticPage.title' });

  return (
    <Page
      className={css.staticPageBase}
      title={title}
      scrollingDisabled={shouldOpenDialog || scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterComponent />}
      >
        <div
          className={classNames(css.handmadecontent, {
            [css['dialog-open']]: shouldOpenDialog,
          })}
        >
          <div id={css['main-canvas']} className={css.container}>
            <div id={css['main-content']}>
              <TheMovementSections />
            </div>
            <BecomeASellerSignupSection
              onOpenSellerForm={setIsShowSellerForm}
              onOpenBuyerForm={setIsShowBuyerForm}
            />
          </div>
          <BottomBar onOpenGatewayDialog={setIsOpenGatewayDialog} />
          <Overlay
            isOpenSellerForm={isShowSellerForm}
            onOpenGatewayDialog={setIsOpenGatewayDialog}
          >
            {isShowSellerForm || isShowBuyerForm ? (
              isShowSellerForm ? (
                <SellerForm />
              ) : (
                <BuyerSignupForm onSubmit={handleSubmitBuyerData} />
              )
            ) : (
              isOpenGatewayDialog && (
                <SignupSection
                  onOpenSellerForm={setIsShowSellerForm}
                  onOpenBuyerForm={setIsShowBuyerForm}
                />
              )
            )}
          </Overlay>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default TheMovementStaticPage;
