import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';

import { isScrollingDisabled } from '../../ducks/ui.duck';

import { LayoutSingleColumn, NamedLink, Page } from '../../components';

import Header from './components/HeaderContainer';
import Footer from './components/FooterContainer';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterComponent from '../FooterContainer/FooterContainer';
import BottomBar from './components/BottomBar';
import Overlay from './components/Overlay';
import SellerForm from './components/SellerForm';
import BuyerSignupForm from './components/BuyerSignupForm';
import SignupSection from './components/SignupSection';
import BecomeASellerSignupSection from './components/BecomeASellerSignupSection';
import { submitBuyerData } from './BecomeASellerStaticPage.duck';

import css from './BecomeASellerStaticPage.module.css';

const BecomeASellerSections = props => {
  const { setIsShowSellerForm, setIsShowBuyerForm } = props;
  return (
    <>
      <section id={css.intro1} className={`${css.container} ${css.full}`}>
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css.text1}>
            <h1>
              A marketplace{' '}
              <span className={css['c-highlight1']}>like no other</span>
            </h1>
            <p>
              The global marketplace dedicated exclusively to handmade products,
              the artisans who make them, and the customers who love them..
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/become-a-seller/section1-image1.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.imagegroup}>
              <div className={`${css.image} ${css.image2}`}>
                <img
                  src="/static/images/become-a-seller/section1-image2.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css.image3}`}>
                <img
                  src="/static/images/become-a-seller/section1-image3.jpg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={css.text2}>
            <h2>
              The world wants to buy{' '}
              <span className={css['c-highlight1']}>what YOU make</span>
            </h2>
            <p>
              Customers across more than 100 nations are searching for unique,
              meaningful products made by artists and artisans who care about
              the quality of their work.
            </p>
          </div>
        </div>
      </section>
      <section id={css.intro2} className={`${css.container} ${css.full}`}>
        <div className={`${css.contentblock} ${css.block1} ${css.spaceright}`}>
          <div className={css.imageblock}>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section2-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <p className={css.arrow}>
              <img src="/static/images/become-a-seller/arrow-right-curved1-highlight1.png" />
            </p>
            <h4>
              Your customers are waiting.{' '}
              <span className={css['c-highlight1']}>Let us introduce you.</span>
            </h4>
            <p>
              For 20+ years, our parent company, NOVICA, has forged significant
              partnerships with National Geographic, the Smithsonian Folklife
              Festival, the International Trade Centre (WTO and United Nations)
              and other key collaborators, developing a customer base of
              millions of deeply loyal individuals who appreciate handmade art.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['new-world-for-artisans']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3}`}>
          <div className={css.text}>
            <h4>
              We're building a{' '}
              <span className={css['c-highlight3']}>whole new world</span> for
              artisans.
            </h4>
            <p>
              Because a better world for artisans is a better world for all.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section3-image1b.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              Where artisans are in the{' '}
              <span className={css['c-highlight3']}>driver's seat.</span>
            </h4>
            <p>
              Recognition, accountability, and transparency are our core values.
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['eveything-is-handmade']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css.text}>
            <h3>
              Everything is{' '}
              <span className={css['c-highlight1']}>handmade.</span>
            </h3>
            <p>
              No imitations, mass produced knock-offs, or cookie-cutter
              products.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={css.imagegroup}>
              <div className={`${css.image} ${css.image1}`}>
                <img
                  src="/static/images/become-a-seller/section4-image1.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css.image2}`}>
                <img
                  src="/static/images/become-a-seller/section4-image3.jpg"
                  loading="lazy"
                />
              </div>
            </div>
            <div className={`${css.image} ${css.image3}`}>
              <img
                src="/static/images/become-a-seller/section4-image2.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              And absolutely{' '}
              <span className={css['c-highlight1']}>NO hidden fees.</span>
            </h4>
            <p>Transparent pricing and lots of seller benefits.</p>
          </div>
        </div>
      </section>

      <section className={`${css.container} ${css.full} ${css.signupblock}`}>
        <div className={css.contentblock}>
          <h3>
            Are you <span className={css['c-highlight1']}>ready?</span>
          </h3>
          <div className={css.links}>
            <div className={css.seller}>
              <h4 className={css['c-highlight1']}>Sellers</h4>
              <p className={css.details}>
                Join the waitlist to apply for a storefront.
              </p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setIsShowSellerForm(true);
                }}
                className={`${css.button} ${css['button-highlight1']} ${
                  css['button-animate']
                } ${css['button-animate-dark']} ${css['fixedwidth']} ${
                  css.dialog
                }`}
                data-dialogtype="sellers"
              >
                Become a seller
                <span
                  className={`${css.icon} material-symbols-outlined ${
                    css['c-white']
                  }`}
                >
                  arrow_right_alt
                </span>
              </a>
            </div>
            <div className={css.buyer}>
              <h4 className={css['c-highlight3']}>Buyers</h4>
              <p className={css.details}>
                Join the waitlist to be notified about our launch.
              </p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setIsShowBuyerForm(true);
                }}
                className={`${css.button} ${css['button-highlight3']} ${
                  css['button-animate']
                } ${css['button-animate-dark']} ${css['fixedwidth']} ${
                  css.dialog
                }`}
                data-dialogtype="buyers"
              >
                Join today
                <span
                  className={`${css.icon} material-symbols-outlined ${
                    css['c-white']
                  }`}
                >
                  arrow_right_alt
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        id={css['more-than-a-marketplace']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1} ${css.spaceright}`}>
          <div className={css.text}>
            <h3>
              More than a marketplace.{' '}
              <span className={css['c-highlight1']}>A movement.</span>
            </h3>
            <p>
              In almost every store. In almost every refrigerator, restaurant,
              closet, winery, gift shop, corner grocery. There it is:
              Artisan-made. Boldly on the label.
            </p>
          </div>          
        </div>
      </section>
      <section
        id={css['why-handmade']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              Why? Because people{' '}
              <span className={css['c-highlight3']}>really do care.</span>
            </h4>
            <p>
              In a world increasingly anonymous and consolidated, we crave
              meaning and authenticity.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/become-a-seller/section5-image1b.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['artisan-community']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css.text}>
            <div className={css['with-arrow']}>
              <span>
                <img src="/static/images/become-a-seller/arrow-right-highlight1.png" />
              </span>
              <h4>
                <span className={css.block}>Welcome Home.</span>
                Not so long ago,{' '}
                <span className={css['c-highlight1']}>
                  most of us were artisans.
                </span>
              </h4>
            </div>
            <p>
              Now, today's creators tether us to that forgotten past.{' '}
              <em>Welcome home</em>, we might start saying to each other.{' '}
              <em>Welcome home.</em>
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/become-a-seller/section6-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              Quality, culture, heritage, authenticity,{' '}
              <span className={css['c-highlight1']}>fair trade.</span>
            </h4>
            <p>A community ready to live by a different set of values.</p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image2}`}>
              <img
                src="/static/images/become-a-seller/section6-image2.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['handmade-is-movement']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block3}`}>
          <div className={css.text}>
            <h3>
              Handmade is a movement of{' '}
              <em className={css['c-highlight3']}>We.</em>
            </h3>
            <p>
              It belongs to us all. It is core to our beings. Fundamental in
              every realm of our lives.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section7-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['happening-now']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              Once in a generation, an industry experiences{' '}
              <span className={css['c-highlight1']}>a massive shift</span>
            </h4>
            <p>
              that disrupts the current landscape and creates a better path.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={css['imagerow']}>
              <div className={css.image}>
                <img
                  src="/static/images/become-a-seller/section8-image1b.jpg"
                  loading="lazy"
                />
              </div>
              <div className={css.image}>
                <img
                  src="/static/images/become-a-seller/section8-image3.jpg"
                  loading="lazy"
                />
              </div>
              <div className={css.image}>
                <img
                  src="/static/images/become-a-seller/section8-image4.jpg"
                  loading="lazy"
                />
              </div>
            </div>
            <div className={css['imagerow']}>
              <div className={css.image}>
                <img
                  src="/static/images/become-a-seller/section8-image5.jpg"
                  loading="lazy"
                />
              </div>
              <div className={css.image}>
                <img
                  src="/static/images/become-a-seller/section8-image2b.jpg"
                  loading="lazy"
                />
              </div>
              <div className={css.image}>
                <img
                  src="/static/images/become-a-seller/section8-image6.jpg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
          <div className={css.text}>
            <h3>
              It's happening. <span className={css['c-highlight1']}>Now.</span>
            </h3>
          </div>
        </div>
      </section>

      <section className={`${css.container} ${css.full} ${css.signupblock}`}>
        <div className={css.contentblock}>
          <h3>
            Are you <span className={css['c-highlight1']}>ready?</span>
          </h3>
          <div className={css.links}>
            <div className={css.seller}>
              <h4 className={css['c-highlight1']}>Sellers</h4>
              <p className={css.details}>
                Join the waitlist to apply for a storefront.
              </p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setIsShowSellerForm(true);
                }}
                className={`${css.button} ${css['button-highlight1']} ${
                  css['button-animate']
                } ${css['button-animate-dark']} ${css['fixedwidth']} ${
                  css.dialog
                }`}
                data-dialogtype="sellers"
              >
                Become a seller
                <span
                  className={`${css.icon} material-symbols-outlined ${
                    css['c-white']
                  }`}
                >
                  arrow_right_alt
                </span>
              </a>
            </div>
            <div className={css.buyer}>
              <h4 className={css['c-highlight3']}>Buyers</h4>
              <p className={css.details}>
                Join the waitlist to be notified about our launch.
              </p>
              <a
                href="#"
                onClick={e => {
                  e.preventDefault();
                  setIsShowBuyerForm(true);
                }}
                className={`${css.button} ${css['button-highlight3']} ${
                  css['button-animate']
                } ${css['button-animate-dark']} ${css['fixedwidth']} ${
                  css.dialog
                }`}
                data-dialogtype="buyers"
              >
                Join today
                <span
                  className={`${css.icon} material-symbols-outlined ${
                    css['c-white']
                  }`}
                >
                  arrow_right_alt
                </span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section
        id={css['about-novica']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              Born of <span className={css['c-highlight1']}>mission</span>,{' '}
              <span className={css['c-highlight1']}>quality</span> and{' '}
              <span className={css['c-highlight1']}>authenticity.</span>
            </h4>
            <p>
              NOVICA, a leader in the handmade movement, has a long-established
              global infrastructure in place with millions of impassioned
              customers in 100 nations around the globe.
            </p>
            <p id={css['logo-novica']}>
              <img
                src="/static/images/landing/full-small-2019.png"
                alt="NOVICA.com"
                width="275"
                height="85"
              />
            </p>
          </div>
          <div className={`${css.text} ${css.reviews}`}>
            <div className={css.review}>
              <p className={css['review-stars']}>
                <span className={css.stars} style={{ '--rating': 5 }}></span>
              </p>
              <div className={css['review-detail']}>
                <p>
                  Thanks for providing people with a way to truly support
                  artists and artisans throughout the world. Being an artist
                  myself, I know firsthand the importance of having one's work
                  fully appreciated and fairly paid for. Bravo to each artisan
                  for their beautiful work. They make me travel around the world
                  in my own home and love it!
                </p>
              </div>
              <p className={css['review-reviewer']}>Veronique Henry</p>
            </div>
            <div className={css.review}>
              <div className={css['review-detail']}>
                <p>
                  Thank you for the confidence and perseverance in being part of
                  our growth process. You have shown us a new market with many
                  opportunities to continue growing and showing the traditions
                  that define us.
                </p>
              </div>
              <p className={css['review-reviewer']}>
                Delfina Ruiz<span>weaver</span>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['is-handmade-right-place']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1}`}>
          <div className={css.imageblock}>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section9-image1b.jpg"
                loading="lazy"
              />
            </div>
          </div>
          <div className={css.text}>
            <h4>
              Sellers, is{' '}
              <span className={css['c-highlight1']}>Handmade.com</span> the
              right place for you?
            </h4>
            <p>
              If you're someone who creates and/or sells handmade products and
              commits to our Ethically Made standards, then we say:{' '}
              <em>Welcome Home!</em>
            </p>
          </div>
        </div>
      </section>
      <section
        id={css['handmade-qualification']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block2} ${css.spaceright}`}>
          <div className={css.text}>
            <h3>
              What qualifies as{' '}
              <span className={css['c-highlight3']}>handmade?</span>
            </h3>
            <p>
              Goods created by hand or goods significantly altered or finished
              by hand. Non-handmade products may qualify if they have been
              repurposed with notable handmade alterations or embellishments.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/become-a-seller/section10-image1.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.imagegroup}>
              <div className={`${css.image} ${css.image1}`}>
                <img
                  src="/static/images/become-a-seller/section10-image2.jpg"
                  loading="lazy"
                />
              </div>
              <div className={`${css.image} ${css.image2}`}>
                <img
                  src="/static/images/become-a-seller/section10-image3.jpg"
                  loading="lazy"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['fasten-your-seatbelts']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.block1} ${css.spaceright}`}>
          <div className={css.text}>
            <h4>
              Fasten your{' '}
              <span className={css['c-highlight1']}>seatbelts.</span>
            </h4>
            <p>
              The road ahead is filled with appreciative people and gorgeous
              discoveries.
            </p>
          </div>
          <div className={css.imageblock}>
            <div className={`${css.image} ${css.image1}`}>
              <img
                src="/static/images/become-a-seller/section11-image1.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
      <section
        id={css['better-together']}
        className={`${css.container} ${css.full}`}
      >
        <div className={`${css.contentblock} ${css.spaceright}"`}>
          <div className={css.text}>
            <h3>
              We're{' '}
              <span className={css['c-highlight1']}>better together.</span>
            </h3>
          </div>
          <div className={css.imageblock}>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-artist-1.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-1.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-2.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-3.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-4.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-6.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-5.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-artist-2.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-7.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-8.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-9.jpg"
                loading="lazy"
              />
            </div>
            <div className={css.image}>
              <img
                src="/static/images/become-a-seller/section-omega-thumb-square-10.jpg"
                loading="lazy"
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const BecomeASellerStaticPage = () => {
  // const [isOpenMobileMenu, setIsOpenMobileMenu] = useState(false);
  const [isOpenGatewayDialog, setIsOpenGatewayDialog] = useState(false);
  const [isShowSellerForm, setIsShowSellerForm] = useState(false);
  const [isShowBuyerForm, setIsShowBuyerForm] = useState(false);

  const scrollingDisabled = useSelector(state => isScrollingDisabled(state));

  useEffect(() => {
    if (!isOpenGatewayDialog) {
      setIsShowSellerForm(false);
      setIsShowBuyerForm(false);
    }
  }, [isOpenGatewayDialog]);

  useEffect(() => {
    if (isShowSellerForm || isShowBuyerForm) {
      setIsOpenGatewayDialog(true);
    }
  }, [isShowSellerForm, isShowBuyerForm]);

  const intl = useIntl();

  const dispatch = useDispatch();
  const onSubmitBuyerData = values => dispatch(submitBuyerData(values));

  const handleSubmitBuyerData = values => {
    return onSubmitBuyerData(values);
  };

  const shouldOpenDialog = isOpenGatewayDialog;

  const title = intl.formatMessage({ id: 'BecomeASellerStaticPage.title' });

  return (
    <Page
      className={css.staticPageBase}
      title={title}
      scrollingDisabled={shouldOpenDialog || scrollingDisabled}
      referrer="origin"
    >
      <LayoutSingleColumn
        topbar={<TopbarContainer />}
        footer={<FooterComponent />}
      >
        <div
          className={classNames(css.handmadecontent, {
            [css['dialog-open']]: shouldOpenDialog,
          })}
        >
          <div id={css['main-canvas']} className={css.container}>
            <div id={css['main-content']}>
              <BecomeASellerSections
                setIsShowSellerForm={setIsShowSellerForm}
                setIsShowBuyerForm={setIsShowBuyerForm}
              />
            </div>
            <BecomeASellerSignupSection
              onOpenSellerForm={setIsShowSellerForm}
              onOpenBuyerForm={setIsShowBuyerForm}
            />
          </div>
          <BottomBar onOpenGatewayDialog={setIsOpenGatewayDialog} />
          <Overlay
            isOpenSellerForm={isShowSellerForm}
            onOpenGatewayDialog={setIsOpenGatewayDialog}
            shouldShowLoader
          >
            {isShowSellerForm || isShowBuyerForm ? (
              isShowSellerForm ? (
                <SellerForm />
              ) : (
                <BuyerSignupForm onSubmit={handleSubmitBuyerData} />
              )
            ) : (
              isOpenGatewayDialog && (
                <SignupSection
                  onOpenSellerForm={setIsShowSellerForm}
                  onOpenBuyerForm={setIsShowBuyerForm}
                />
              )
            )}
          </Overlay>
        </div>
      </LayoutSingleColumn>
    </Page>
  );
};

export default BecomeASellerStaticPage;
